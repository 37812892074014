<template>
  <div>
    <b-row>
      <b-col>
        <div class="settings-medium-title mb-2 mt-3">
          {{ $t('views.credits.credits-label') }}
        </div>
        <div class="mb-2">
          <template v-for="(model, paymentTokenKey) in subscriptionPlanPrice.paymentTokens">
            <b-row
              :key="'paymentTokenKey' + paymentTokenKey"
              class="mt-2"
            >
              <b-col cols="6">
                <label class="col-form-label-sm field-name-class">
                  {{ $t('components.form.credits-pack.credit-pack-informations.credit-quantity') }}
                </label>
              </b-col>
              <b-col cols="5">
                <label class="col-form-label-sm field-name-class">
                  {{ $t('components.form.credits.credit-informations.type') }}
                </label>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="6">
                <d-text-field
                  v-model.number="model.quantity"
                  :disabled="isDisabled"
                  :rules="isDisabled ? undefined : validation.number"
                  size="sm"
                  type="number"
                  min="0"
                  class-name="background-light-blue-inputs"
                  error-text="validation.is-a-number"
                />
              </b-col>
              <b-col cols="5">
                <validation-provider
                  v-slot="{ errors, ariaInput }"
                  :rules="validation.required"
                >
                  <select
                    v-model="model.paymentToken"
                    :disabled="isDisabled"
                    size="sm"
                    class="form-control background-light-blue-inputs"
                  >
                    <option disabled :value="null">
                      {{ $t('general.actions.choose') }}
                    </option>
                    <template v-for="(credit, creditKey) in credits">
                      <option
                        :key="'credit' + creditKey"
                        :value="credit['@id']"
                        :disabled="isDisabledIri(credit['@id'])"
                      >
                        {{ credit.name }}
                      </option>
                    </template>
                  </select>
                  <div
                    v-if="errors.length > 0"
                    class="d-block invalid-feedback"
                  >
                    {{ $t('validation.required') }}
                  </div>
                </validation-provider>
              </b-col>
              <b-col>
                <span
                  v-if="!isDisabled"
                  class="fa fa-minus text-danger pointer mt-2"
                  @click="removeCredit(model.paymentToken)"
                />
              </b-col>
            </b-row>
          </template>
        </div>

        <span
          v-if="!isDisabled"
          class="pointer fa fa-plus text-blue-light"
          @click="addCredit"
        >
          <label class="simple-label-avenir-blue ml-2 pointer">
            {{ $t('views.subscriptions.payment-tokens.add-payment-token') }}
          </label>
        </span>
        <span
          v-if="isDisabled && subscriptionPlanPrice.paymentTokens.length === 0"
          class="d-flex justify-content-center black-col-title"
        >
          {{ $t('views.subscriptions.payment-tokens.no-credits') }}
        </span>
      </b-col>
      <b-col cols="3" class="description-gray pr-0">
        {{ $t('views.subscriptions.payment-tokens.description') }}
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {getCredits} from "@api/doinsport/services/credits/credits.api";
import {isNotUndefinedAndNotNull} from "@/utils/classes";

export default {
  components: {
    AddCredit: () => import('@custom/subscrtiptions/new/AddItemLink')
  },
  data: () => ({
    credits: [],
    validation: require('@validation/entities/doinsport/Default.json'),

  }),
  props: {
    subscriptionPlanPrice: {
      type: Object,
      default: () => {
      }
    },
    isDisabled: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    isDisabledIri(iri) {
      const findPaymentToken = this.subscriptionPlanPrice.paymentTokens.find(el => el.paymentToken === iri);
      return isNotUndefinedAndNotNull(findPaymentToken);
    },
    loadPaymentTokens() {
      getCredits()
        .then((response) => {
          this.credits = response.data['hydra:member'];
        })
      ;
    },
    addCredit() {
      this.subscriptionPlanPrice.paymentTokens.push({
        paymentToken: null,
        quantity: null
      });
    },
    removeCredit(paymentToken) {
      let indexToRemove = -1;

      for (let index = 0; index < this.subscriptionPlanPrice.paymentTokens.length; index ++) {
        if (this.subscriptionPlanPrice.paymentTokens[index].paymentToken === paymentToken) {
          indexToRemove = index;
          break;
        }
      }

      if (indexToRemove !== -1) {
        this.subscriptionPlanPrice.paymentTokens.splice(indexToRemove, 1);
      }
    }
  },
  created() {
    this.loadPaymentTokens();
  }
}
</script>
