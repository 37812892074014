import {_delete, _get, _post, _put, clubId} from "@api/doinsport/services/httpService";

const URL = 'clubs/payment-tokens';

export const getCredit = (id) => _get(URL + '/' + id);

export const postCredit = (credit) => _post(URL , credit);

export const putCredit = (credit) => _put(URL + '/' + credit.id  , credit);

export const getCredits = (itemsPerPage = 100, page = 1, search = '') => {
  const endPoint = URL+'?club.id=' + clubId + '&itemsPerPage='+ itemsPerPage + '&page=' + page +'&search=' + search;

  return _get(endPoint);
}

export const deleteCredit = (id) => _delete(URL + '/' + id);
